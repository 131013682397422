import { ADD_PROFESSIONALINFO } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setNewerProfessionalInfo } from "../../../actions/profile/professionalInfo";
import { add } from "../../../../repositories/profiles/professionalInfo";
import { setNotification } from "../../../actions/common";

const addProfessionalInfo = (action$) =>
  action$.pipe(
    ofType(ADD_PROFESSIONALINFO),
    switchMap(({ payload }) =>
      from(add(payload)).pipe(
        flatMap((resp) =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully added"),
            }),
            setNewerProfessionalInfo(resp)
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

export default addProfessionalInfo;
