export const fr = "fr";
export const en = "en";
export const ar = "ar";
export const es = "es";
export const de = "de";
export const it = "it";
export const english = "English";
export const français = "Français";
export const arabic = "العربية";
export const spanish = "Español";
export const italien = "Italiano";
export const deutch = "Deutsch";

export const paid = "paid";
export const unpaid = "unpaid";

export const bilingual = 2;
export const native = 3;

export const firstContact = "First Contact";
export const positionned = "Positionned";
export const onWaiting = "On Waiting";
export const aborted = "Aborted";
export const accepted = "Accepted";

export const basic = 1;
export const familiar = 2;
export const senior = 3;
export const expert = 4;

export const functionnal = "functionnal";
export const technical = "technical";
export const tool = "tool";
export const other = "other";

export const male = "male";
export const female = "female";

export const In = "in";
export const out = "out";

export const purchase = "purchase";
export const charge = "charge";
export const invoice = "invoice";
export const subscription = "subscription";
export const rent = "rent";
export const salary = "salary";
export const reimbursement = "reimbursement";
export const bank_fees = "bank_fees";
export const tax = "tax";
export const insurance = "insurance";
export const sale = "sale";
export const subsidy = "subsidy";
export const interests = "interests";

export const caHt = "caHt";
export const caTtc = "caTtc";

export const reminder = "reminder";
export const autoInput = "autoInput";

export const delta = "delta";
export const specificInterval = "specificInterval";

export const fixed = "fixed";
export const calculatedOnTurnoverExclTaxes = "calculatedOnCaExclTaxes";
export const calculatedOnTurnoverInclTaxes = "calculatedOnCaInclTaxes";

export const euro = "€";
export const currencyLookup = () => ({
  [euro]: "Euro"
})

export const currencyMapping = (currency) => {
  const mapping = {
    euro: "€",
  };

  return mapping[currency.toLowerCase()] || currency;
};

export const preferredLanguagesLookup = { [fr]: français, [en]: english, [ar]: arabic, [es]: spanish, [de]: deutch, [it]: italien };
export const billStateLookup = () => ({
  [paid]: "Paid",
  [unpaid]: "Unpaid",
});
export const cvSkillsTypeLookup = () => ({
  [functionnal]: "Functionnal",
  [technical]: "Technical",
  [tool]: "Tool",
  [other]: "Other",
});
export const cvLanguageLevelLookup = () => ({
  [bilingual]: "Bilingual",
  [native]: "Native",
});
export const offerStatusLookup = () => ({
  [firstContact]: "First Contact",
  [positionned]: "Positionned",
  [onWaiting]: "On Waiting",
  [aborted]: "Aborted",
  [accepted]: "Accepted",
});
export const cvSkillsLevelLookup = () => ({
  [basic]: "Basic",
  [familiar]: "Familiar",
  [senior]: "Senior",
  [expert]: "Expert",
});
export const profileGenderLookup = () => ({
  [male]: "Male",
  [female]: "Female",
});

export const transactionTypeLookup = () => ({
  [purchase]: "Purchase",
  [charge]: "Charge",
  [invoice]: "Invoice",
  [subscription]: "Subscription",
  [rent]: "Rent",
  [salary]: "Salary",
  [reimbursement]: "Reimbursement",
  [bank_fees]: "Bank fees",
  [tax]: "Tax & Duties",
  [insurance]: "Insurance",
  [sale]: "Sale",
  [subsidy]: "Subsidy",
  [interests]: "Interests",
  [other]: "Other",
});

export const transactionOperationLookup = () => ({
  [In]: "In",
  [out]: "Out",
});

export const amountTypeLookup = () => ({
  [fixed]: "Fixed amount",
  [calculatedOnTurnoverExclTaxes]: "Amount based on turnover excl tax",
  [calculatedOnTurnoverInclTaxes]: "Amount based on turnover incl tax",
});

export const operationSourceLookup = () => ({
  [caTtc]: "Turnover including tax",
  [caHt]: "Turnover excluding taxes",
});

export const actionLookup = (operation) => ({
  [reminder]: "Validation required before payment",
  [autoInput]: "Automatic {operation} on my account".replace("{operation}", operation === In ? "credit" : "debit")
});

export const intervalLookup = () => ({
  [delta]: "Calculation since last execution",
  [specificInterval]: "Custom period",
});

export const subProductType_recurring = "recurring";
export const subProductType_oneTime = "one_time";

export const subProductInterval_day = "day";
export const subProductInterval_week = "week";
export const subProductInterval_month = "month";
export const subProductInterval_year = "year";

export const subProductType = () => ({
  [subProductType_oneTime]: "One-off",
  [subProductType_recurring]: "Recurring",
})

export const subProductInterval = () => ({
  [subProductInterval_day]: "day",
  [subProductInterval_week]: "week",
  [subProductInterval_month]: "month",
  [subProductInterval_year]: "year",
})

export const frenshImpots = (revenu) => {
  const bareme = {
    0: 10777,
    11: 27478,
    30: 78570,
    41: 168994,
    45: 168995,
  };
  let impot = 0;
  let previousLevel = 0;
  Object.keys(bareme).every((level) => {
    impot +=
      (level *
        ((revenu > bareme[level] ? bareme[level] : revenu) -
          bareme[previousLevel])) /
      100;
    previousLevel = level;
    if (revenu < bareme[level]) return false;
    return true;
  });

  return impot;
};

export const allColors = [
  "#1de9b6",
  '#008B8B',
  "#007FFF",
  '#9966CC',
  '#FF91AF',
  '#dc3545',
  '#FF0800',
  '#FF9966',
  '#DDD06A',
  '#993300',
  '#FF66CC',
  '#FDBCB4',
  '#536878',
  '#B2BEB5',
  '#C1F85C',
  '#AA98A9',
  '#36454F',
]

export const severityColor = {
  "warning": "#ffc107",
  "error": "#dc3545"
}