import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import { editSignature } from "../../../../repositories/profiles/professionalInfo";
import { setNotification } from "../../../actions/common";
import { UPDATE_SIGNATURE } from "../../../constants/actions-types";
import { setSignature } from "../../../actions/profile/professionalInfo";

const updateSignature = (action$) =>
  action$.pipe(
    ofType(UPDATE_SIGNATURE),
    switchMap(({ payload }) =>
      from(editSignature(payload.id, payload.content)).pipe(
        flatMap(() =>
          of(
            setSignature({ id: payload.id, signature: payload.content })
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

export default updateSignature;
