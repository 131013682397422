import i18next from "i18next";

export const dateNow = () => Date.now();
export const newDate = (date) => date ? new Date(date) : new Date();
export const setDate = (year, month, day, hours, minutes, secondes) => new Date(year, month, day, hours ?? 0, minutes ?? 0, secondes ?? 0);
export const getYear = (date) => newDate(date).getFullYear();
export const getMonth = (date) => newDate(date).getMonth();
export const getDay = (date) => newDate(date).getDate();
export const getDayOfWeek = (date) => newDate(date).getDay();
export const getHours = (date) => newDate(date).getHours();
export const getMinutes = (date) => newDate(date).getMinutes();
export const getSeconds = (date) => newDate(date).getSeconds();
export const getTime = (date) => newDate(date).getTime();

export const months = () => {
  return {
    12: "December",
    11: "November",
    10: "October",
    9: "September",
    8: "August",
    7: "July",
    6: "June",
    5: "May",
    4: "April",
    3: "March",
    2: "February",
    1: "January",
  };
};

export const days = () => {
  return {
    0: "Sun",
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
  };
};

export const daysNumber = () => {
  return {
    "1": "1",
    "2": "2",
    "3": "3",
    "4": "4",
    "5": "5",
    "6": "6",
    "7": "7",
    "8": "8",
    "9": "9",
    "10": "10",
    "11": "11",
    "12": "12",
    "13": "13",
    "14": "14",
    "15": "15",
    "16": "16",
    "17": "17",
    "18": "18",
    "19": "19",
    "20": "20",
    "21": "21",
    "22": "22",
    "23": "23",
    "24": "24",
    "25": "25",
    "26": "26",
    "27": "27",
    "28": "28",
    "29": "29",
    "30": "30",
    "31": "31",
    lastDay: "Last day"
  }
}

export const completeDays = () => {
  return {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thusday",
    5: "Friday",
    6: "Saturday",
  };
};

export const quarters = () => {
  return {
    1: "1st Quarter",
    2: "2nd Quarter",
    3: "3rd Quarter",
    4: "4th Quarter",
  };
};

export const getQuarter = (month) => {
  return Math.ceil(month / 3);
};

export const years = (startYear, maxFuture = 5) => {
  var maxYear = getYear() + maxFuture,
    years = {};
  startYear = startYear || 1970;
  while (startYear <= maxYear) {
    var year = startYear++;
    years[year] = year;
  }

  return years;
};

const formatDate = (date, withTime) => {
  let month = "" + (getMonth(date) + 1),
    day = "" + getDay(date),
    year = getYear(date),
    hours = getHours(date),
    minutes = getMinutes(date),
    secondes = getSeconds(date);

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  if (secondes < 10) secondes = "0" + secondes;

  return withTime
    ? `${[day, month, year].join("/")} ${[hours, minutes, secondes].join(":")}`
    : [day, month, year].join("/");
};

export const getDateTime = (date) => {
  return formatDate(date, true);
};

export const getDate = (date) => {
  return formatDate(date, false);
};

export const getTimeOnly = (date) => {
  let hours = getHours(date),
    minutes = getMinutes(date);

  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;

  return [hours, minutes].join(":");
};

export const getStartOfDay = (date) => {
  let d = newDate(date);

  return newDate(d.setHours(0, 0, 0, 0));
};

export const dateToSecondes = (date) => {
  return getTime(date) / 1000; //secondes
};

export const endOfYear = (date) => {
  let year = getYear(date);
  return [12, 31, year].join("/") + " 23:59:59";
};

export const lastDayofMonth = (date) => {
  return setDate(getYear(date), getMonth(date) + 1, 0);
};

export const firstDayofMonth = (date) => {
  return setDate(getYear(date), getMonth(date), 1);
};

export const GetAge = (date) => {
  let age = getYear() - getYear(date);
  var m = getMonth() - getMonth(date);
  if (m < 0 || (m === 0 && getDay() < getDay(date))) {
    age--;
  }
  return age;
};

export const addDays = (date, days) => {
  if (!date || !days) return date;
  const dateCopy = newDate(date);
  dateCopy.setDate(getDay(date) + days);

  return dateCopy;
};

export const getMonthDays = (month, year) => {
  let date = setDate(year, month, 0);
  const daysCount = getDay(date);
  date = setDate(year, month - 1, 1);
  const firstDay = getDayOfWeek(date);

  let result = {};
  for (var i = 0; i < daysCount; i++) {
    result[i + 1] = days()[(firstDay + i) % 7];
  }
  return result;
};

export const getWorkingDays = (month, year, holidays) => {
  const monthDays = getMonthDays(month, year);
  const workingDays = [];
  Object.keys(monthDays).forEach((d) => {
    if (
      monthDays[d] !== days()[0] &&
      monthDays[d] !== days()[6] &&
      (!holidays || !holidays[month] || !holidays[month].includes(parseInt(d)))
    )
      workingDays.push(parseInt(d));
  });
  return workingDays;
};

export const dateToMinutes = (date, fromStart) => {
  var d = dateToSecondes(date, fromStart);
  return d / 60; //minutes
};

export const dateToHours = (date, fromStart) => {
  var d = dateToMinutes(date, fromStart);
  return d / 60; //hours
};

export const dateToDays = (date, fromStart) => {
  var d = dateToHours(date, fromStart);
  return d / 24; //hours
};

export const daysToDate = (date, fromStart) => {
  return getDateTime(date * 24 * 60 * 60 * 1000, fromStart);
};

export const hoursToDate = (date, fromStart) => {
  return getDateTime(date * 60 * 60 * 1000, fromStart);
};

export const minutesToDate = (date, fromStart) => {
  return getDateTime(date * 60 * 1000, fromStart);
};

export const minutesFromDate = (date, fromStart) => {
  return getDateTime(date * 60 * 1000, fromStart);
};

export const combineDateAndTime = (date, time) => {
  const timeString = getHours(time) + ":" + getMinutes(time) + ":00";

  var year = getYear(date);
  var month = getMonth(date) + 1; // Jan is 0, dec is 11
  var day = getDay(date);
  var dateString = "" + year + "-" + month + "-" + day;
  return newDate(dateString + " " + timeString);
};

export const getDateStringFormat = (dateTime, withTime) => {
  const hours = getHours(dateTime) < 10 ? "0" + getHours(dateTime) : getHours(dateTime),
    minutes = getMinutes(dateTime) < 10 ? "0" + getMinutes(dateTime) : getMinutes(dateTime),
    day = getDayOfWeek(dateTime),
    date = getDay(dateTime),
    month = getMonth(dateTime) + 1,
    year = getYear(dateTime);

  return withTime ? `${i18next.t(completeDays()[day])} ${date} ${i18next.t(months()[month])} ${year} ${hours}:${minutes}`
    : `${i18next.t(completeDays()[day])} ${date} ${i18next.t(months()[month])} ${year}`
};

export const datesDifference = (date1, date2) => {
  const pastDate = newDate(date1);
  // Get the current time
  const futureDate = newDate(date2);
  // Calculate the difference in milliseconds
  const difference = futureDate - pastDate;

  // Convert the difference to seconds
  const secondes = Math.floor(difference / 1000);

  // Calculate the elapsed time in seconds, minutes, hours, and days
  const elapsedSeconds = secondes % 60;
  const elapsedMinutes = Math.floor(secondes / 60) % 60;
  const elapsedHours = Math.floor(secondes / (60 * 60)) % 24;
  const elapsedDays = Math.floor(secondes / (60 * 60 * 24));

  return {
    days: elapsedDays,
    hours: elapsedHours,
    minutes: elapsedMinutes,
    secondes: elapsedSeconds,
    millisecondes: difference
  };
}

export const cronTask = (secondes, func) => {
  const intervalId = setInterval(func, secondes * 1000);
  return () => {
    clearInterval(intervalId);
  };
}

export const getLastMonths = (count, startDate = newDate()) => {
  const result = [];

  for (let i = 0; i < count; i++) {
    // Create a new date, subtracting `i` months from the start date
    const date = newDate(startDate);
    date.setMonth(getMonth(date) - i); // Handles month/year overflow automatically

    result.push({
      month: getMonth(date) + 1, // Months are zero-indexed, so add 1 for human-readable format
      year: getYear(date),  // Get the adjusted year
    });
  }

  return result;
};