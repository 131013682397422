// import * as actionTypes from "../../actions/common/index";
import { dateNow } from "../../../utils/date";
import * as actionTypes from "../../constants/actions-types";

const initialState = {
  notification: { isSuccess: true, message: "", lastCallDateTime: undefined },
};

const handlers = {
  [actionTypes.SET_NOTIFICATION](state, payload) {
    return {
      ...state,
      notification: {
        isSuccess: payload.isSuccess,
        message: payload.message,
        lastCallDateTime: dateNow(),
      },
    };
  },
  [actionTypes.SIGNOUT]() {
    return initialState;
  },
  [actionTypes.SET_SIGN_UP]() {
    return initialState;
  },
};

const CommonReducer = (state = initialState, { type, payload }) => {
  const actionHandler = handlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
};

export default CommonReducer;
