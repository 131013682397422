import { fr } from "../../../utils/constants";
import {
  SET_USER_DATA,
  SIGNOUT,
  SET_SIGN_UP,
  SET_USER_ABILITY,
  SET_AUTH,
  SET_USER_PICTURE,
  SET_PARAMS,
  SET_USER_PERMISSIONS,
  SET_PROFESSIONALINFOS
} from "../../constants/actions-types";

const initialState = {
  isSignUp: false,
  isLoading: false,
  params: { preferredLanguage: fr, isDiscreet: false, activeProfile: 0 },
  user: {
    data: {},
    permissions: [],
    ability: {},
  },
};

const handlers = {
  [SET_USER_DATA](state, payload) {
    return payload.isLogin ?
      {
        ...state,
        params: { ...initialState.params, ...{ ...payload.data.params, ...{ preferredLanguage: payload.data.params.preferredLanguage ?? initialState.params.preferredLanguage } } },
        user: {
          id: payload.data.id, userName: payload.data.userName, permissions: payload.data.permissions, ability: payload.data.ability, data:
          {
            ...state.user.data, id: payload.data.id, gender: payload.data.gender, firstName: payload.data.firstName,
            lastName: payload.data.lastName, mail: payload.data.mail, phone: payload.data.phone, birthDate: payload.data.birthDate, startDate: payload.data.startDate, isFree: payload.data.isFree, freePeriod: payload.data.freePeriod,
          }
        },
      } : {
        ...state,
        user: {
          ...state.user,
          id: payload.data.id, userName: payload.data.userName,
          data: {
            ...state.user.data, id: payload.data.id, gender: payload.data.gender, firstName: payload.data.firstName,
            lastName: payload.data.lastName, mail: payload.data.mail, phone: payload.data.phone, birthDate: payload.data.birthDate, startDate: payload.data.startDate, isFree: payload.data.isFree, freePeriod: payload.data.freePeriod,
          }
        }
      };
  },
  [SET_AUTH](state, payload) {
    return {
      ...state,
      auth: payload
    };
  },
  [SET_USER_ABILITY](state, payload) {
    return {
      ...state,
      user: {
        ...state.user,
        ability: payload,
      },
    };
  },
  [SET_USER_PERMISSIONS](state, payload) {
    return {
      ...state,
      user: {
        ...state.user,
        permissions: payload.permissions,
        ability: payload.ability,
        data: {
          ...state.user.data,
          isFree: payload.isFree,
          freePeriod: payload.freePeriod
        }
      },
    };
  },
  [SET_PARAMS](state, payload) {
    return {
      ...state,
      params: payload,
    };
  },
  [SET_PROFESSIONALINFOS](state, payload) {
    return {
      ...state,
      params: {
        ...state.params,
        activeProfile: state.params.activeProfile === 0 && payload.length > 0 ? payload[0].id : state.params.activeProfile
      }
    }
  },
  [SET_USER_PICTURE](state, payload) {
    return {
      ...state,
      user: {
        ...state.user,
        data: {
          ...state.user.data,
          pictureName: payload.name,
          pictureContent: payload.content
        }
      },
    };
  },
  [SIGNOUT](state) {
    return {
      ...state,
      user: initialState.user,
      isSignUp: initialState.isSignUp,
      isLoading: initialState.isLoading,
    };
  },
  [SET_SIGN_UP](state) {
    return {
      ...state,
      isSignUp: true,
    };
  },
};

const AuthenticationReducer = (state = initialState, { type, payload }) => {
  const actionHandler = handlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
};

export default AuthenticationReducer;
