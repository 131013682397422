import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "./Locales/en/translation_en.json";
import frTranslations from "./Locales/fr/translation_fr.json";
import arTranslations from "./Locales/ar/translation_ar.json";
import esTranslations from "./Locales/es/translation_es.json";
import deTranslations from "./Locales/de/translation_de.json";
import itTranslations from "./Locales/it/translation_it.json";

const resources = {
  en: { messages: enTranslations },
  fr: { messages: frTranslations },
  es: { messages: esTranslations },
  ar: { messages: arTranslations },
  de: { messages: deTranslations },
  it: { messages: itTranslations },
};
const init_i18n = i18next.use(initReactI18next);
const i18n = (userLanguage) => {
  init_i18n.init({
    react: {
      wait: true,
    },
    resources: resources,
    lng: userLanguage,
    fallbackLng: userLanguage,
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
    ns: ["messages"],
    defaultNS: "messages",
    fallbackNS: [],
  });

  return init_i18n;
};

export default i18n;
