import { UPDATE_LAST_CONNECTION_DATE } from "../../constants/actions-types";
import { mergeMap } from "rxjs/operators";
import { merge, of } from "rxjs";
import { ofType } from "redux-observable";
import { updateLastConnectionDate } from "../../../repositories/accessManagement/user";

const updateLastLoginDate = (action$) =>
  action$.pipe(
    ofType(UPDATE_LAST_CONNECTION_DATE),
    mergeMap(() =>
      merge(
        // of(fetchOptionSet()),
        of(updateLastConnectionDate())
      )
    )
  );

export default updateLastLoginDate;
