import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import { editLogo } from "../../../../repositories/profiles/professionalInfo";
import { setNotification } from "../../../actions/common";
import { UPDATE_LOGO } from "../../../constants/actions-types";
import { setLogo } from "../../../actions/profile/professionalInfo";

const updateLogo = (action$) =>
  action$.pipe(
    ofType(UPDATE_LOGO),
    switchMap(({ payload }) =>
      from(editLogo(payload.id, payload.content)).pipe(
        flatMap(() =>
          of(
            setLogo({ id: payload.id, logo: payload.content })
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

export default updateLogo;
