import {
  FETCH_PROFESSIONALINFO,
  SET_PROFESSIONALINFO,
  SET_NEWER_PROFESSIONALINFO,
  SET_DELETED_PROFESSIONALINFO,
  SET_PROFESSIONALINFOS,
  ADD_PROFESSIONALINFO,
  UPDATE_PROFESSIONALINFO,
  DELETE_PROFESSIONALINFO,
  UPDATE_TVAINFO,
  UPDATE_BANKINFO,
  UPDATE_LOGO,
  SET_PROFESSIONALINFO_LOGO,
  UPDATE_SIGNATURE,
  SET_PROFESSIONALINFO_SIGNATURE
} from "../../../constants/actions-types";

export const fetchProfessionalInfo = () => ({
  type: FETCH_PROFESSIONALINFO,
  payload: {},
});

export const updateLogo = (data) => ({
  type: UPDATE_LOGO,
  payload: data,
});

export const setLogo = (data) => ({
  type: SET_PROFESSIONALINFO_LOGO,
  payload: data,
});

export const updateSignature = (data) => ({
  type: UPDATE_SIGNATURE,
  payload: data,
});

export const setSignature = (data) => ({
  type: SET_PROFESSIONALINFO_SIGNATURE,
  payload: data,
});

export const setProfessionalInfo = (data) => ({
  type: SET_PROFESSIONALINFO,
  payload: data,
});

export const setNewerProfessionalInfo = (data) => ({
  type: SET_NEWER_PROFESSIONALINFO,
  payload: data,
});

export const setDeletedProfessionalInfo = (data) => ({
  type: SET_DELETED_PROFESSIONALINFO,
  payload: data,
});

export const setProfessionalInfos = (data) => ({
  type: SET_PROFESSIONALINFOS,
  payload: data,
});

export const addProfessionalInfo = (data) => ({
  type: ADD_PROFESSIONALINFO,
  payload: data,
});

export const updateProfessionalInfo = (data) => ({
  type: UPDATE_PROFESSIONALINFO,
  payload: data,
});

export const updateTvaInfo = (data) => ({
  type: UPDATE_TVAINFO,
  payload: data,
});

export const updateBankInfo = (data) => ({
  type: UPDATE_BANKINFO,
  payload: data,
});

export const deleteProfessionalInfo = (index) => ({
  type: DELETE_PROFESSIONALINFO,
  payload: index,
});
