import { FETCH_PROFESSIONALINFO } from "../../../constants/actions-types";
import { mergeMap, flatMap, catchError, finalize } from "rxjs/operators";
import { of, from, merge } from "rxjs";
import { ofType } from "redux-observable";

import { setProfessionalInfos } from "../../../actions/profile/professionalInfo";
import { getAll } from "../../../../repositories/profiles/professionalInfo";
import { setNotification, setLoading } from "../../../actions/common";
import { dispatch } from "../../../../store";

const fetchProfessionalInfo = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_PROFESSIONALINFO),
    mergeMap(() =>
      merge(
        of(setLoading(true)),
        from(getAll()).pipe(
          flatMap((resp) =>
            of(setProfessionalInfos(resp))
          ),
          catchError((error) =>
            of(
              setNotification({
                isSuccess: false,
                message: "Error : " + error,
              })
            )
          ),
          finalize(() => dispatch(setLoading(false)))
        )
      )
    )
  );

export default fetchProfessionalInfo;
