import { Get, Post, Put, Delete } from "../../utils/base-api-request";
import { store } from "../../store/index";
import { getTime } from "../../utils/date";
import { paid, unpaid } from "../../utils/constants";

const getTransactionsUrl =
  process.env.REACT_APP_BASE_API + "api/transaction/all";
const getTransactionsFilteredUrl =
  process.env.REACT_APP_BASE_API + "api/transaction/filters";
const getTransactionChargesUrl =
  process.env.REACT_APP_BASE_API + "api/transaction/charges";
const getUnpaidTransactionUrl =
  process.env.REACT_APP_BASE_API + "api/transaction/{profileId}/{count}";
const getTransactionUrl = process.env.REACT_APP_BASE_API + "api/transaction";
const removeTransactionUrl = process.env.REACT_APP_BASE_API + "api/transaction";
const addTransactionUrl = process.env.REACT_APP_BASE_API + "api/transaction";
const updateTransactionUrl = process.env.REACT_APP_BASE_API + "api/transaction";
const getStatisticsUrl =
  process.env.REACT_APP_BASE_API + "api/transaction/statistic/{profileId}";
const getChargesByExternalIdUrl =
  process.env.REACT_APP_BASE_API + "api/transaction/all/{profileId}/{externalId}";

export const sortData = (array) => {
  return array.sort((a, b) => {
    if (a.status === b.status) {
      if (a.status === paid) return a.paymentDate < b.paymentDate ? 1 : -1;
      else return a.expectedPaymentDate < b.expectedPaymentDate ? 1 : -1;
    }
    return a.status === unpaid ? -1 : 1;
  });
};

export const getAlls = async () => {
  const transactions = await Get(
    getTransactionsUrl + "/" + store.getState().authentication.params.activeProfile
  );
  return sortData(
    transactions.map((transaction) =>
      transactionDtoToTransactionView(transaction)
    )
  );
};

export const getAll = async (editedFilters) => {
  const transactions = await Post(
    getTransactionsFilteredUrl,
    filterViewToDto(editedFilters)
  );
  return sortData(
    transactions.map((transaction) =>
      transactionDtoToTransactionView(transaction)
    )
  )
};

export const getUnpaid = async (count = 0) => {
  const transactions = await Get(
    getUnpaidTransactionUrl.replace("{profileId}", store.getState().authentication.params.activeProfile)
      .replace("{count}", count)
  );
  return sortData(
    transactions.map((transaction) =>
      transactionDtoToTransactionView(transaction)
    )
  );
};

export const getStatistics = async (years) => {
  return await Post(
    getStatisticsUrl
      .replace("{profileId}", store.getState().authentication.params.activeProfile), years)
};

export const getCharges = async () => {
  const transactions = await Get(
    getTransactionChargesUrl + "/" + store.getState().authentication.user.id
  );
  return sortData(
    transactions.map((transaction) =>
      transactionDtoToTransactionView(transaction)
    )
  );
};

export const getChargesById = async (id) => {
  const transactions = await Get(
    getChargesByExternalIdUrl
      .replace("{profileId}", store.getState().authentication.params.activeProfile)
      .replace("{externalId}", id)
  );
  return sortData(
    transactions.map((transaction) =>
      transactionDtoToTransactionView(transaction)
    )
  );
};

export const get = async (id) => {
  return transactionDtoToTransactionView(
    await Get(getTransactionUrl + "/" + id)
  );
};

export const add = async (transaction) => {
  if (transaction["userId"] === undefined)
    transaction["userId"] = store.getState().authentication.user.id;
  if (transaction["profileId"] === undefined)
    transaction["profileId"] = store.getState().authentication.params.activeProfile;
  const response = await Post(
    addTransactionUrl,
    transactionViewToTransactionDto(transaction)
  );
  return transactionDtoToTransactionView(response);
};

export const update = async (transaction) => {
  if (transaction["userId"] === undefined)
    transaction["userId"] = store.getState().authentication.user.id;

  const response = await Put(
    updateTransactionUrl,
    transactionViewToTransactionDto(transaction)
  );
  return transactionDtoToTransactionView(response);
};

export const remove = async (id) => {
  return await Delete(removeTransactionUrl + "/" + id);
};

export const lookupData = (transactions) => {
  let lookup = {};
  transactions.forEach(
    (transaction) => (lookup[transaction.id] = transaction.reference)
  );

  return lookup;
};

const transactionDtoToTransactionView = (item) => {
  return {
    ...item,
    attachmentIds: item.attachmentIds ? item.attachmentIds : [],
    // serviceExecutionDate: formatDate(item.serviceExecutionDate),
    // creationDate: formatDate(item.creationDate),
    // paimentDate: formatDate(item.paimentDate),
    // penaltyDate: formatDate(item.penaltyDate),
  };
};

const transactionViewToTransactionDto = (item) => {
  return {
    ...item,
    attachmentIds: item.attachmentIds ? item.attachmentIds : [],
    paymentDate:
      item.status === paid
        ? item.paymentDate > 0
          ? getTime(item.paymentDate)
          : item.paymentDate
        : 0,
    expectedPaymentDate: getTime(item.expectedPaymentDate),
    modifiedBy: store.getState().authentication.user.userName,
    createdBy: item.createdBy
      ? item.createdBy
      : store.getState().authentication.user.userName,
  };
};

const filterViewToDto = (filterView) => {
  const filterDto = {
    profileId: store.getState().authentication.params.activeProfile,
    quantity: filterView.quantity,
    // creationDate: {
    //   min: filterView.startDate
    //     ? filterView.startDate - newDate().getTimezoneOffset() * 60000
    //     : filterView.startDate,
    //   max: filterView.endDate
    //     ? filterView.endDate - newDate().getTimezoneOffset() * 60000
    //     : filterView.endDate
    // }
  };

  if (filterView.filtersField) {
    if (filterView.filtersField.type && filterView.filtersField.type.length > 0)
      filterDto.type = transformObject(filterView.filtersField.type)

    if (filterView.filtersField.creationDate && filterView.filtersField.creationDate.length > 0)
      filterDto.creationDate = transformObject(filterView.filtersField.creationDate)

    if (filterView.filtersField.status && filterView.filtersField.status.length > 0)
      filterDto.status = transformObject(filterView.filtersField.status)

    if (filterView.filtersField.operation && filterView.filtersField.operation.length > 0)
      filterDto.operation = transformObject(filterView.filtersField.operation)

    if (filterView.filtersField.expectedPaymentDate && filterView.filtersField.expectedPaymentDate.length > 0)
      filterDto.expectedPaymentDate = transformObject(filterView.filtersField.expectedPaymentDate)

    if (filterView.filtersField.lastModificationDate && filterView.filtersField.lastModificationDate.length > 0)
      filterDto.lastModificationDate = transformObject(filterView.filtersField.lastModificationDate)

    if (filterView.filtersField.paymentDate && filterView.filtersField.paymentDate.length > 0)
      filterDto.paymentDate = transformObject(filterView.filtersField.paymentDate)

    if (filterView.filtersField.amount && filterView.filtersField.amount.length > 0)
      filterDto.amount = transformObject(filterView.filtersField.amount)

    if (filterView.filtersField.vat && filterView.filtersField.vat.length > 0)
      filterDto.vat = transformObject(filterView.filtersField.vat)

    if (filterView.filtersField.amountTTC && filterView.filtersField.amountTTC.length > 0)
      filterDto.amountTTC = transformObject(filterView.filtersField.amountTTC)

    if (filterView.filtersField.reference && filterView.filtersField.reference.length > 0)
      filterDto.reference = transformObject(filterView.filtersField.reference)

    if (filterView.filtersField.currency && filterView.filtersField.currency.length > 0)
      filterDto.currency = transformObject(filterView.filtersField.currency)

    if (filterView.filtersField.modifiedBy && filterView.filtersField.modifiedBy.length > 0)
      filterDto.modifiedBy = transformObject(filterView.filtersField.modifiedBy)
  }

  return filterDto
}

const transformObject = (inputObject) => {
  const operatorMap = {};

  // Iterate through the input object's type array
  inputObject.forEach(item => {
    const operator = item.operator;
    const value = item.value;

    // If the operator is already in the operatorMap, merge the values
    if (operatorMap[operator]) {
      if (!Array.isArray(operatorMap[operator].value)) {
        operatorMap[operator].value = [operatorMap[operator].value];
      }
      operatorMap[operator].value.push(value);
    } else {
      operatorMap[operator] = { operator, value: [value] };
    }
  });

  // Convert the operatorMap back to an array and assign it to the transformedObject
  return Object.values(operatorMap);
}
