import { DELETE_PROFESSIONALINFO } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setDeletedProfessionalInfo } from "../../../actions/profile/professionalInfo";
import { remove } from "../../../../repositories/profiles/professionalInfo";
import { setNotification } from "../../../actions/common";
import { updateParams } from "../../../actions/authentication";

const deleteProfessionalInfo = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_PROFESSIONALINFO),
    switchMap(({ payload }) =>
      from(remove(payload)).pipe(
        flatMap(() =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully deleted"),
            }),
            setDeletedProfessionalInfo(payload),
            updateParams({
              ...state$.value.authentication.params,
              ...{
                activeProfile: getNewProfileId(state$.value.dataSet.profiles, state$.value.authentication.params.activeProfile, payload)
              }
            })
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: i18next.t("Unable to delete this company because it is associated with billing line items Please remove the billing lines before trying again"),
            })
          )
        )
      )
    )
  );

const getNewProfileId = (profiles, activeId, deletedId) => {
  const newProfiles = profiles.filter(p => p.id !== deletedId);
  return parseInt(deletedId) === parseInt(activeId)
    ? newProfiles.length > 0 ? profiles[0].id : 0
    : activeId
}

export default deleteProfessionalInfo;
